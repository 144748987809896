const dataLayer = (window as any).dataLayer || [];
(window as any).dataLayer = dataLayer;

// tslint:disable-next-line:only-arrow-functions
(window as any).gtag = function(...args: any[]) { dataLayer.push(arguments) }
gtag('js', new Date())

if (gon.ga.trackerId != null) {
  gtag('config', gon.ga.trackerId, {
    send_page_view: false,
  })
}
